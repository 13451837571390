<template>
  <div id="Case">
    <h2>东莞市松山湖北区学校</h2>
    <!-- <p>Dongguan Songshan Lake North District School</p> -->
    <div class="case-box">
      <p>
        方案为学校定制了具有北区特色的北斗七星少年评价体系，定制化学生个性成长报告单，教师积分管理体系，学校督导评价体系。通过评价立校数据大脑、学生综合素质评价平台、教师智慧教研平台、教师专业发展平台、学生学业水平测试平台、学校督导评价平台等系列模块建设，推动了松山湖北区学校的全面发展。
      </p>
      <div class="imgBox">
        <div class="left">
          <img
            src="../../../assets/images/partnersCase/cases/case4_item1.png"
            alt=""
          />
        </div>
        <div class="right">
          <img
            src="../../../assets/images/partnersCase/cases/case4_item2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      margin: 50px 0;
    }
    .imgBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .left {
        width: 40%;
      }
      .right {
        width: 50%;
      }
      img {
        width: 70%;
      }
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;

      .imgBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        .left {
          width: 60%;
          margin: 20px auto;
        }
        .right {
          width: 60%;
          margin: 20px auto;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;

    p {
      font-size: 14px;
      line-height: 28px;
      margin: 30px 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 40px;
    .imgBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
